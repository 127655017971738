import Vue from 'vue'
import UiKit from '@motadata/ui'
import i18n from './i18n'
import App from './app'
import router from '@router'
import store from '@state/store'
import modules from '@modules'
import wait from '@plugins/wait'
import './_init'
import './plugins'
import './validations'
import './greetings'

Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = true

// import LazyResourceLoader from '@utils/load-lazy-resources'
Vue.use(UiKit)

// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

const app = new Vue({
  router,
  store,
  i18n,
  modules,
  wait,
  render: (h) => h(App),
}).$mount('#app')

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}

// Hot updates for translatations
// if (module.hot) {
//   module.hot.accept(['./locales/en'], function() {
//     i18n.setLocaleMessage('en', require('./locales/en').default)
//   })
// }
